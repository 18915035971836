@function rem($val) {
  @return ($val/14)*1rem;
}

// 隐藏UNIX时间戳
pre.sf-dump {
  display: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 设置滚动条样式 */
::-webkit-scrollbar {
  display: none;
  width: 8px;
  background-color: #f8f8f8;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;

}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbarthumb:window-inactive {
  background-color: rgba(255, 0, 0, 0.4);
}

html,
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #fff;
}

@media (max-width:1600px) {

  html,
  body {
    font-size: 14px;

  }
}

@media (max-width:1400px) {

  html,
  body {
    font-size: 12px;
  }
}

#header {
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  color: #fff;

  .nav {
    -webkit-user-select: none;
    user-select: none;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 4.715rem;
    transition: background-color 1s;

    &.bg {
      background-color: rgba(0, 0, 0, .6);
    }

    #header-logo {
      // flex-grow: 1;
      padding: 1.571rem rem(60);

      img {
        height: 1.571rem;
      }
    }

    #header-menus {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;
      align-self: stretch;
      font-size: 1rem;
      line-height: 1.5;
      color: #fff;

      .nav-item {
        position: relative;
        padding: 0 1.714rem;

        .nav-link {
          display: block;
          position: relative;
          padding: 0;
          font-size: 1rem;
          line-height: 4.715rem;
          color: rgba(255, 255, 255, .8);
          cursor: pointer;

          &.active {
            font-weight: bold;
            color: #fff;

            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              height: 2px;
              background: #E60819;
            }
          }
        }

        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          padding: 0;
          background: transparent;
          border: none;
          border-radius: 0;



          .dropdown-item {
            display: block;
            padding: 1.714rem 1.928rem;
            font-size: 1rem;
            color: #333;
            background: rgba(255, 255, 255, .6);
            transition: all .3s;

            &:hover {
              background: #fff;
            }
          }
        }

        &.down {
          .nav-link {
            .icon {
              transform: rotateZ('180deg');
            }
          }

          .dropdown-menu {
            display: block;

          }
        }
      }

      .dropdown-mask {
        display: none;
        position: fixed;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, .3);
        backdrop-filter: blur(5px);
      }
    }

    #header-search {
      padding: 1.571rem 5.7rem;

      #search-btn {
        color: #fff;
        transition: color .3s;

        &:hover {
          color: #E60819;
        }
      }
    }
  }

  #searchview {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    .search_mask {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      backdrop-filter: blur(5px);
    }

    .searchview_wrapper {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 6rem;
      background-color: #fff;
      // border-radius: 0 0 10px 10px;
      box-shadow: 0 20px 40px 0 rgba(184, 157, 157, 0.2);
      transition: transform .8s cubic-bezier(.2, 1, .3, 1), opacity .8s cubic-bezier(.2, 1, .3, 1);

      .container {
        position: relative;

        #header-search {
          display: block;
          width: 100%;
          padding: 0.5rem 1rem;
          font-size: 1.2rem;
          outline: none;
          background: transparent;
          border: none;
          border-bottom: 2px solid #666;
        }

        #search-close {
          position: absolute;
          right: 1rem;
          top: 0;
          bottom: 0;
          margin: auto;
          padding: 0 0.6rem;
          font-size: 1.2rem;
          line-height: 2rem;
          height: 2rem;
          color: #999;
          background-color: rgba(0, 0, 0, 0);
          transition: all .3s;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, .2);
          }
        }
      }
    }
  }
}

.end {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  width: 100%;
  aspect-ratio: 1440/336;
  color: #fff;
  // background: rgba(0, 0, 0, 0.3);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .container {
    position: relative;
    height: 100%;
  }

  .row {
    height: 100%;

    .title {
      margin-bottom: rem(21);
      font-size: rem(32);
      font-weight: bold;
      line-height: 1.5;
    }

    .desc {
      margin: 0;
      font-size: rem(16);
    }

    .info {
      padding: 0;
      font-size: rem(14);
      line-height: 1.5;

      p {
        margin-bottom: rem(34);

        &:last-of-type {
          margin-bottom: 0;
        }

        span,
        a {
          display: inline-block;
          font-weight: bold;
          margin-left: rem(25);
          // margin-right: rem(20);
        }

        a {
          color: inherit;
          transition: color .3s;

          &:hover {
            color: #ddd;
          }
        }

        i {
          display: inline-block;
        }
      }

    }
  }

  .btn-red {
    display: inline-block;
    position: relative;
    padding: rem(15) rem(30);
    font-size: rem(12);
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, .5);
    border: none;
    border-radius: none;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      background-color: #E60819;
      transition: width .3s;
    }

    span,
    i {
      display: inline-block;
      position: relative;
      color: #fff;
    }

    i {
      margin-left: .5rem;
    }



    &:hover {
      &::before {
        width: 0%;
      }
    }
  }
}

#footer {
  padding-top: rem(72);
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;

  .ps-60 {
    padding-left: rem(60);
  }

  .qrcode-box {
    position: relative;
    margin-right: rem(40);

    .icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-size: 24px;
      color: #fff;
      background-color: #B6B7B9;
      border-radius: 50%;
      transition: background-color .3s;
    }

    .box {
      display: none;
      position: absolute;
      left: 50%;
      bottom: 55px;
      transform: translateX(-50%);
      white-space: nowrap;
      padding: 10px;
      border-radius: 6px;
      box-shadow: 0px 9px 21px 0px rgba(34, 45, 53, 0.2);

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-10px);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
      }

      .qrcode {
        display: inline-block;
        max-width: 90px;
        aspect-ratio: 1;
      }
    }

    &:hover {
      .icon {
        background-color: #E60819;
      }

      .box {
        display: block;

      }
    }
  }

  .detail {
    margin-bottom: 190px;
    font-size: rem(24);
    font-weight: bold;
    line-height: rem(40);
    white-space: pre-line;
  }

  .head {
    margin-bottom: rem(24);
    font-size: rem(18);
    font-weight: bold;
    color: #333;
  }

  .cat {
    padding-left: 1rem;

    a {
      display: inline-block;
      position: relative;
      padding: rem(8) 0;
      font-size: rem(14);
      text-decoration: none;
      color: #333;
      opacity: .5;


      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 0%;
        background: #E60819;
        transition: all .3s;
      }

      &:hover {
        font-weight: bold;
        opacity: 1;

        &::before {
          width: 100%;
        }
      }
    }
  }

  .bottom {
    margin-top: rem(55);
    padding: rem(15);
    font-size: rem(11);
    line-height: 1.5;
    color: #545454;
    text-align: center;
    border-top: 1px solid #d8d8d8;

    span {
      display: inline-block;
      margin: 0 rem(10);

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          color: #E60819;
          text-decoration: underline;
        }
      }
    }

  }
}

#banner {
  -webkit-user-select: none;
  user-select: none;
  aspect-ratio: 144/40;
  position: relative;
  color: #fff;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: linear-gradient(180deg, rgba(51, 56, 70, 0.3) 0%, rgba(51, 56, 70, 0) 100%);
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-left: rem(56);
    padding-bottom: rem(64);

    .title {
      margin-bottom: rem(24);
      font-size: rem(32);
      font-weight: bold;
    }

    .desc {
      font-size: rem(14);
    }
  }
}

#nav {
  border-bottom: 1px solid #D8D8D8;
  -webkit-user-select: none;
  user-select: none;

  .nav {
    .nav-item {
      padding: 0 rem(35);

      .nav-link {
        display: block;
        position: relative;
        padding: rem(20) 0;
        font-size: rem(16);
        text-decoration: none;
        color: #333;
        opacity: .3;
        transition: all .3s;

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 0%;
          height: 3px;
          background-color: #e60819;
          transition: width .3s;
        }

        &.active {
          font-weight: bold;
          color: #333 !important;
          opacity: 1;

          &::after {
            width: 100%;
          }
        }

        &:hover {
          font-weight: bold;
          color: #e60819;
          opacity: 1;
        }
      }
    }
  }
}

.text-red {
  color: #E60819 !important;
}

// 分页
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: rem(40) 0;
  font-size: rem(14);
  color: rgba(0, 0, 0, .65);
  -webkit-user-select: none;
  user-select: none;

  li {
    margin: 0 4px;

    a,
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      min-width: 32px;
      text-decoration: none;
      color: rgba(0, 0, 0, .65);
      background-color: #fff;
      border-radius: 8px;
      transition: all .3s;
    }

    a {
      &:hover {
        color: #333;
        background-color: #d9d9d9;
        box-shadow: 0px 9px 21px 0px rgba(34, 45, 53, 0.1);
      }
    }

    span {
      opacity: .5;
    }

    &.active {
      span {
        opacity: 1;
        color: #FFF;
        background-color: #E60819;
      }
    }
  }
}

#contact-btn,
#email-btn {
  display: block;
  position: fixed;
  z-index: 999;
  width: rem(56);
  height: rem(56);
  font-size: rem(26);
  line-height: rem(56);
  text-align: center;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

#contact-btn {
  right: rem(60);
  bottom: rem(112);
  background-color: #E60819;

  &:hover {
    background: linear-gradient(180deg, #E60819 0%, #F04949 100%, #CB3D3D 100%);
    box-shadow: 0px 13px 17px 0px rgba(215, 77, 77, 0.5);
  }
}

#email-btn {
  right: rem(60);
  bottom: rem(48);
  background: rgba(44, 44, 44, 0.3);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(111, 111, 111, 0.3) 100%);
    box-shadow: 0px 13px 17px 0px rgba(189, 189, 189, 0.5);
  }
}

// 面包屑
.breadcrumb {
  margin-bottom: rem(32);
  padding: rem(20) 0;
  font-size: rem(18);
  border-bottom: 1px solid #d8d8d8;
  -webkit-user-select: none;
  user-select: none;

  .item {
    a {
      text-decoration: none;
      color: rgba(51, 51, 51, .5);
      transition: color .3s;

      &:hover {
        color: #E60819;
      }
    }

    &.link {
      &::after {
        content: ">";
        display: inline-block;
        margin: 0 rem(24);
        color: rgba(51, 51, 51, .5);
      }
    }

    span {
      color: #000;
    }
  }
}

// 获客表单
#float-contact {
  display: none;
  position: fixed;
  z-index: 1000;
  right: rem(60);
  bottom: rem(112);
  padding: 24px;

  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 13px 17px 0px rgba(76, 82, 97, 0.1);

  .head {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333;
    -webkit-user-select: none;
    user-select: none;

    .close-btn {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(51, 51, 51, .5);
      transition: all .3s;

      &:hover {
        color: rgba(51, 51, 51, 1);
        background-color: #f4f4f4;
      }
    }
  }

  .form-item {
    width: 300px;
    margin-bottom: 24px;

    .form-label {
      font-size: 14px;
      color: #333;
      -webkit-user-select: none;
      user-select: none;
    }

    .form-control {
      padding: 15px 16px;
      font-size: 14px;
      line-height: 1;
      background: #fff;
      border: 1px solid #ECECEC;
      border-radius: 6px;

      &.is-invalid {
        border-color: #E60819;
      }
    }
  }

  .btn-submit {
    display: block;
    position: relative;
    padding: 15px;
    width: 100%;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 6px;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      background: #E60819;
      transition: all .3s;
    }

    &:hover {
      &::before {
        width: 0%;
      }
    }

    span,
    i {
      display: inline-block;
      position: relative;
    }

    .icon {
      top: 2px;
      width: 14px;
      height: 14px;
      margin-left: 26px;
      background-image: url('/image/emc/more.png');
      background-position: bottom;
      background-size: 100%;
      background-repeat: no-repeat;
    }

  }
}



// 页面反馈
#message-box {
  position: fixed;
  z-index: 999;
  top: 10%;
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
  padding: 24px 32px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 6px 19px 40px 0px rgba(84, 85, 85, 0.08);

  .title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #333;

    i {
      width: 16px;
      font-size: 18px;
      margin-right: 8px;
    }

    &.success {
      color: #E60819
    }

    &.error {
      color: #7d7d7d;
    }
  }

  .desc {
    margin: 0 0 0 28px;
    font-size: 14px;
    color: #333;
  }
}

.my-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, .5);
  // backdrop-filter: blur(5px);

  .box {
    padding: 20px 32px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 6px 19px 40px 0px rgba(84, 85, 85, 0.08);

    .title {
      margin-bottom: 14px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    .msg {
      margin: 0;
      font-size: 14px;
      color: #333;
    }
  }
}